import React, {ChangeEvent, useState} from 'react';
import {headerSearchContent} from 'components/header/search/locale';
import {getCurrentLocale} from 'services/locale';
import {useRouter} from 'next/router';
import {useMediaMdQuery} from 'hooks/mediaQuery';

import styles from 'components/header/search/styles.module.css';

export function HeaderSearch() {
  const content = headerSearchContent[getCurrentLocale()];

  const [active, setActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const router = useRouter();
  const matchesMd = useMediaMdQuery();

  const handleSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleBlurSearch = () => {
    if (!searchValue) {
      setActive(false);
    }
  };

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && searchValue) {
      router.push(`/freelancers?search=${searchValue}`);
    }
  }

  const onCloseBtn = () => {
    setActive(false);
    setSearchValue('');
  };

  const notActiveSearchNode = (
    <button
      className="flex text-lg text-alto"
      onClick={() => setActive(true)}
      data-testid="search-icon-btn"
    >
      <i className="icon-search" />
    </button>
  );

  const closeBtn = !!searchValue && (
    <button
      data-testid="close-btn"
      onClick={onCloseBtn}
      className="shrink-0 w-4 h-4 rounded-[50%] border border-solid border-naturalGray flex items-center justify-center"
    >
      <i className="icon-cross text-[0.375rem] text-naturalGray flex" />
    </button>
  );

  const activeSearchClassNames = [
    styles.activeSearch,
    'bg-dune rounded-[100px] flex items-center justify-start py-0.5 px-2.5',
  ].join(' ');

  const activeSearchNode = (
    <div className={activeSearchClassNames}>
      <i className="icon-search text-alto text-lg flex" />

      <input
        data-testid="search-input"
        className="mx-2 w-full bg-transparent text-white font-normal text-base placeholder:text-alto placeholder:font-light placeholder:text-base"
        placeholder={matchesMd ? content.placeholder : content.shortPlaceholder}
        autoFocus
        value={searchValue}
        onChange={handleSearchValue}
        onBlur={handleBlurSearch}
        onKeyDown={handleKeyDown}
      />

      {closeBtn}
    </div>
  );

  const contentNode = active ? activeSearchNode : notActiveSearchNode;

  return (
    <div className="flex justify-end w-full md:w-[25rem]">{contentNode}</div>
  );
}
