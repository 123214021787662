import React, {useEffect} from 'react';
import {Button} from 'components/button';
import {headerContent} from 'components/header/locale';
import {getCurrentLocale} from 'services/locale';
import {Navigation} from 'components/navigation';
import {useUser} from 'context/user';
import {useRouter} from 'next/router';
import {logout} from 'components/header/services';

import styles from 'components/menu/styles.module.css';

type Props = {
  open: boolean;
  onClose: () => void;
};

export function Menu(props: Props) {
  const {open, onClose} = props;

  const content = headerContent[getCurrentLocale()];

  const {user, isUserLoading, setUser} = useUser();
  const router = useRouter();

  useEffect(() => {
    if (open) {
      window.document.documentElement.style.overflow = 'hidden';
    } else {
      window.document.documentElement.style.overflow = 'visible';
    }
  }, [open]);

  const handleAuth = (path: string) => {
    router.push(path);
    onClose();
  };

  const handleLogOut = async () => {
    await logout(setUser);
    router.push('/sign-in');
    onClose();
  };

  function getActionButtonProps() {
    if (user) {
      return {children: content.signOut, onClick: handleLogOut};
    }

    if (router?.pathname === '/sign-in') {
      return {children: content.signUp, onClick: () => handleAuth('/register')};
    }

    return {children: content.signIn, onClick: () => handleAuth('/sign-in')};
  }

  const actionButtonProps = getActionButtonProps();

  const actionBtn = (
    <div className="border-t border-solid border-dune pt-7 px-8 md:px-10">
      <div className="w-full md:w-fit">
        <Button
          variant="outlined"
          size="small"
          fullWidth
          onClick={actionButtonProps.onClick}
        >
          {actionButtonProps.children}
        </Button>
      </div>
    </div>
  );

  const actionNode = !isUserLoading && actionBtn;

  const menuClassNames = [
    styles.menu,
    'max-w-[17rem] w-full h-full bg-black absolute right-0 top-0 py-4 md:py-5 md:max-w-[24rem]',
  ].join(' ');

  const contentNode = (
    <div
      className="w-screen h-screen fixed top-0 right-0 bg-[rgba(0,0,0,0.6)] z-50"
      data-testid="sidebar-menu"
    >
      <div className={menuClassNames}>
        <div className="flex pr-6 pl-10 pb-4 md:pb-5">
          <button className="ml-auto" onClick={onClose}>
            <i className="icon-cross text-white text-base" />
          </button>
        </div>

        <Navigation usage="menu" />

        {actionNode}
      </div>
    </div>
  );

  return open ? contentNode : null;
}
