import {useEffect, useMemo, useState} from 'react';
import {getMatchMedia} from 'utils/browser';

export function useMediaQuery(query: string): boolean {
  const matchMedia = useMemo(() => getMatchMedia(), []);

  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const getMatches = (value: string): boolean => {
      return matchMedia?.(value)?.matches || false;
    };

    const handleChange = () => {
      setMatches(getMatches(query));
    };

    const matchMediaRes = matchMedia?.(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    matchMediaRes?.addEventListener('change', handleChange);

    return () => {
      matchMediaRes?.removeEventListener('change', handleChange);
    };
  }, [query, matchMedia]);

  return matches;
}

export const useMediaMdQuery = () => useMediaQuery('(min-width: 768px)');
export const useMediaLgQuery = () => useMediaQuery('(min-width: 1024px)');
export const useMediaXlQuery = () => useMediaQuery('(min-width: 1280px)');
