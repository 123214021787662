import React from 'react';
import {getCurrentLocale} from 'services/locale';
import {useUser} from 'context/user';
import {navigationContent} from 'components/navigation/locale';
import {useRouter} from 'next/router';
import {toast} from 'react-toastify';

type Props = {
  usage: 'header' | 'menu';
};

type LinkItem = {
  title: string;
  link: string;
};

export function Navigation(props: Props) {
  const {usage} = props;

  const content = navigationContent[getCurrentLocale()];

  const router = useRouter();
  const user = useUser().user;

  function handleRedirect(link: string) {
    if (router.pathname === '/create-account') {
      toast.error(content.finishCreatingAccountError);
      return;
    }

    router.push(link);
  }

  const getNavigationListItem = (item: LinkItem) => {
    const {title, link} = item;

    const classNames = [
      'font-normal text-base uppercase text-alto underline decoration-transparent hover:decoration-alto',
      usage === 'header' ? 'mr-14' : 'block mb-10 md:mb-14',
    ].join(' ');

    return (
      <button className={classNames} onClick={() => handleRedirect(link)}>
        {title}
      </button>
    );
  };

  const getNavigationListNode = () => {
    const listContent = [];

    // user has Production role
    if (user?.is_production) {
      listContent.push({title: content.freelancers, link: '/freelancers'});
    }

    if (user) {
      listContent.push({title: content.myInfo, link: '/my-info'});
    }

    listContent.push({title: content.contactUs, link: '/contact-us'});

    const classNames =
      usage === 'header'
        ? 'hidden xl:flex items-center justify-start ml-7'
        : 'px-8 md:px-10';

    return (
      <div className={classNames}>{listContent.map(getNavigationListItem)}</div>
    );
  };

  return getNavigationListNode();
}
