import React, {useState} from 'react';
import NextLink from 'next/link';
import {Button} from 'components/button';
import {getCurrentLocale} from 'services/locale';
import {headerContent} from 'components/header/locale';
import {Menu} from 'components/menu';
import {Navigation} from 'components/navigation';
import {useUser} from 'context/user';
import {logout} from 'components/header/services';
import {HeaderSearch} from 'components/header/search';
import {useRouter} from 'next/router';
import {useMediaMdQuery} from 'hooks/mediaQuery';
import {toast} from 'react-toastify';
import {navigationContent} from 'components/navigation/locale';

export function Header() {
  const content = headerContent[getCurrentLocale()];
  const menuContent = navigationContent[getCurrentLocale()];

  const [openMenu, setOpen] = useState(false);

  const {user, isUserLoading, setUser} = useUser();
  const router = useRouter();
  const matchesMd = useMediaMdQuery();
  const role = router.query?.role;
  const roleOption = role ? '?role=production' : '';

  // user has Production role
  const headerSearchNode = user?.is_production &&
    router.pathname !== '/freelancers' && (
      <div className="mr-5 w-full md:mr-6 md:w-auto">
        <HeaderSearch />
      </div>
    );

  const handleLogout = async () => {
    await logout(setUser);
    router.push(`/sign-in${roleOption}`);
  };

  function getActionButton() {
    if (user) {
      return (
        <Button size="small" onClick={handleLogout} data-testid="logout-btn">
          {content.signOut}
        </Button>
      );
    }

    if (router?.pathname === '/sign-in') {
      return (
        <NextLink href={`/register${roleOption}`}>
          <a>
            <Button size="small">{content.signUp}</Button>
          </a>
        </NextLink>
      );
    }

    return (
      <NextLink href={`/sign-in${roleOption}`}>
        <a>
          <Button size="small">{content.signIn}</Button>
        </a>
      </NextLink>
    );
  }

  function handleLogoClick() {
    if (router.pathname === '/create-account') {
      toast.error(menuContent.finishCreatingAccountError);
      return;
    }

    router.push(`/${roleOption}`);
  }

  const actionNode = !isUserLoading && (matchesMd || !headerSearchNode) && (
    <div className="shrink-0">{getActionButton()}</div>
  );

  return (
    <>
      <header className="h-14 py-1.5 border-b border-solid border-b-dune fixed top-0 left-0 w-full z-50 bg-black">
        <div className="content flex items-center justify-start">
          <button onClick={handleLogoClick}>
            <img
              src="/logo@2x.png"
              className="w-9 cursor-pointer mr-5"
              alt="NEOM"
            />
          </button>

          <Navigation usage="header" />

          <div className="flex items-center justify-end ml-auto w-full md:w-auto">
            {headerSearchNode}
            {actionNode}

            <button
              className="ml-5 md:ml-6 xl:hidden"
              onClick={() => setOpen(true)}
              data-testid="menu-btn"
            >
              <i className="icon-menu text-white text-base" />
            </button>
          </div>
        </div>
      </header>

      <Menu open={openMenu} onClose={() => setOpen(false)} />
    </>
  );
}
