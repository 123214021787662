import React from 'react';

type Variant = 'contained' | 'outlined' | 'transparent' | 'error';
type Color = 'primary' | 'secondary';
type Size = 'extra-small' | 'small' | 'medium';

type BaseButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export type Props = Omit<BaseButtonProps, 'className'> & {
  variant?: Variant;
  size?: Size;
  color?: Color;
  fullWidth?: boolean;
};

export function Button(props: Props) {
  const {
    variant = 'contained',
    size = 'medium',
    color = 'primary',
    children,
    type = 'button',
    fullWidth = true,
    ...otherProps
  } = props;

  function getVariantClassNames(variant: Variant) {
    switch (variant) {
      case 'outlined':
        return 'bg-transparent text-white border-alto hover:text-black hover:bg-white disabled:bg-naturalGray disabled:border-none disabled:text-black';
      case 'error':
        return 'bg-coralRed text-white border-transparent hover:bg-white hover:text-black';
      case 'transparent':
        return 'bg-transparent text-tussock border-none w-fit py-0 text-left text-xs md:text-sm hover:text-tulipTree';
      case 'contained':
      default:
        return 'bg-white text-black border-transparent hover:bg-tussock disabled:bg-naturalGray';
    }
  }

  function getSizeClassNames(size: Size) {
    switch (size) {
      case 'small':
        return 'py-1.5 text-sm tracking-wider h-8 whitespace-pre';
      case 'extra-small':
        return 'py-0 text-[10px] tracking-widest';
      default:
        return 'py-3 text-sm tracking-widest';
    }
  }

  const buttonClassNames = [
    getVariantClassNames(variant),
    getSizeClassNames(size),
    color === 'secondary' ? 'bg-tussock hover:bg-white' : '',
    fullWidth ? 'w-full' : '',
    variant === 'transparent' ? 'px-0' : 'px-5',
    'rounded-[6.25rem] font-normal uppercase border border-solid flex items-center justify-center flex-nowrap',
  ].join(' ');

  return (
    <button {...otherProps} type={type} className={buttonClassNames}>
      {children}
    </button>
  );
}
