import {isClient} from 'utils/env';

export function isSafariBrowser() {
  return window?.navigator.userAgent.indexOf('Safari') > -1;
}

export function isFirefoxBrowser() {
  return window?.navigator.userAgent.indexOf('Firefox') > -1;
}

export function getMatchMedia() {
  return isClient() ? window?.matchMedia : undefined;
}

export function getIntersectionObserver() {
  return isClient() ? window.IntersectionObserver : undefined;
}
