import React, {ReactNode} from 'react';
import {Header} from 'components/header';
import {useUser} from 'context/user';
import {useRouter} from 'next/router';

import styles from 'components/layout/styles.module.css';

export type Props = {
  meta: ReactNode;
  children: ReactNode;
  isAuthRequired: boolean;
};

export function Layout({meta, children, isAuthRequired}: Props) {
  const {user, isUserLoading} = useUser();

  const router = useRouter();

  const layoutClassNames = [
    styles.layout,
    'flex flex-col min-h-screen pt-14',
  ].join(' ');

  return (
    <div className={layoutClassNames}>
      {meta}

      <Header />

      {renderPageContentOrNull()}
    </div>
  );

  function renderPageContentOrNull() {
    if (!isAuthRequired || user) return <>{children}</>;

    if (isAuthRequired && !isUserLoading) {
      router.replace('/sign-in');
      return;
    }

    return null;
  }
}
